<template>
  <div>
    <div class="d-flex flex-row mb-6">
      <p class="text-2xl mb-6">Privacy Policy</p>
    </div>

    <div class="wpb_wrapper">
      <ol>
        <li>
          <span style="color: #333333"
            ><strong
              >Pourquoi vos données personnelles sont collectées et traitées par
              REWORLD MEDIA ?</strong
            ></span
          >
        </li>
      </ol>
      <p>
        REWORLD MEDIA est amené à collecter et traiter des données, et notamment
        des données à caractère personnel, concernant ses clients, ses
        prestataires, leurs employés et les personnes prenant contact avec elle.
        Toute collecte de données répond à une finalité précise qui est
        clairement indiquée à la personne concernée sur les formulaires ainsi
        que le cadre légal ou contractuel qui s'y rattache le cas échéant.
      </p>
      <p>
        REWORLD MEDIA traite vos données à des fins d'envoi de newsletters
        thématiques (news, people, sport, culture, cuisine et loisirs …), de
        prospection commerciale et de campagnes publicitaires ciblées par voie
        électronique (mail, sms, mms). Par notre intermédiaire, vous pouvez
        bénéficier des bons plans de nos partenaires et sous-traitants.
      </p>
      <p>&nbsp;</p>
      <ol start="2">
        <li>
          <span style="color: #333333"
            ><strong
              >Quelles sont les finalités du traitement des données par REWORLD
              MEDIA ?</strong
            ></span
          >
        </li>
      </ol>
      <p>
        REWORLD MEDIA collecte et utilise uniquement les données personnelles
        qui nous sont nécessaires dans le cadre de notre activité pour vous
        proposer une offre personnalisée et de qualité.
      </p>
      <p>Nous collectons directement auprès de vous :</p>
      <p>
        - Des informations d'identification et de contact (sexe, nom, prénom,
        date de naissance, code postal, adresse électronique, numéro de
        téléphone de manière facultative) si vous vous inscrivez directement
        auprès de notre société pour recevoir nos communications en remplissant
        notre formulaire de contact.
      </p>
      <p>
        - Nous collectons également de manière indirecte des informations
        d'identification et de contact vous concernant lorsque vous participez à
        des opérations d'animation commerciale organisées par un de nos
        partenaires (telle que l'animation de jeux concours) et que vous avez
        consenti à ce que vos données soient transmises aux sponsors de ladite
        opération.
      </p>
      <p>&nbsp;</p>
      <p>
        Nous collectons en outre directement auprès de vous les catégories de
        données personnelles suivantes :
      </p>
      <p>
        Données de navigation et de connexion (adresse IP, données de connexion
        et données de navigation) grâce à l'utilisation d'outils tels que
        cookies, tag, balises. En effet, à l'ouverture de nos newsletters et
        communications, des cookies sont déposés sur votre ordinateur, votre
        mobile ou votre tablette. Il s'agit de cookies REWORLD MEDIA et de
        cookies tiers qui permettent de vous proposer, grâce une segmentation
        marketing (ou profilage marketing sans effet juridique pour les
        personnes concernées), des publicités ciblées adaptées à vos centres
        d'intérêts et de réaliser des statistiques.
      </p>
      <p>
        Données d'interaction entre vous et notre société telles que l'ouverture
        ou lien des newsletters que nous adressons, clics sur les liens dans les
        newsletters que nous adressons. Ces informations nous permettent
        d'adapter les échanges que nous entretenons avec vous.
      </p>
      <p>
        REWORLD MEDIA s'assure que seules les données nécessaires à la
        réalisation de nos services ne soient collectées auprès des personnes
        concernées lors de la souscription, de l'abonnement et l'ouverture de
        nos newsletters et communications ainsi que lors d'opérations
        d'animation commerciale que nous pouvons réaliser.
      </p>
      <p>
        Ci-dessous la liste des bases de données et des newsletters concernées :
      </p>
      <p>- Auto Moto : https://www.auto-moto.com/</p>
      <p>- F1i : https://f1i.auto-moto.com/</p>
      <p>- Football 365 : https://www.football365.fr/</p>
      <p>- Football : https://www.football.fr/</p>
      <p>- Sports : https://www.sports.fr/</p>
      <p>- Dz Foot : http://www.dzfoot.com/</p>
      <p>- Be : https://www.be.com/</p>
      <p>- Gourmand : https://gourmand.viepratique.fr/</p>
      <p>- Maison &amp; Travaux : https://www.maison-travaux.fr/</p>
      <p>
        - Mon Jardin &amp; ma maison :
        https://monjardinmamaison.maison-travaux.fr/
      </p>
      <p>- Le journal de la Maison : https://www.lejournaldelamaison.fr/</p>
      <p>- marie france : https://www.mariefrance.fr/</p>
      <p>- Vie Pratique Féminin : https://www.viepratique.fr/</p>
      <p>- Tests de Produits : http://www.testsdeproduits.fr/</p>
      <p>- Top Santé : https://www.topsante.com/</p>
      <p>- Biba : https://www.bibamagazine.fr/</p>
      <p>- Pleine Vie : https://www.pleinevie.fr/</p>
      <p>- Autoplus : https://www.autoplus.fr/</p>
      <p>- Closer : https://www.closermag.fr/</p>
      <p>- Grazia : https://www.grazia.fr/</p>
      <p>- Science &amp; Vie : https://www.science-et-vie.com/</p>
      <p>- Téléstar : https://www.telestar.fr/</p>
      <p>&nbsp;</p>
      <p>
        Lorsque vos données sont utilisées à des fins de prospection directe par
        e-mail ou SMS, votre consentement préalable est requis.
      </p>
      <p>
        Il peut ainsi vous être proposé de recevoir des offres commerciales :
      </p>
      <p>- du groupe REWORLD MEDIA</p>
      <p>- de ses partenaires.</p>
      <p>
        Par exception, et conformément à la réglementation applicable, dans le
        cas où ces coordonnées ont été collectées dans le cadre de la vente d'un
        produit ou d'une prestation de service et qu'elles sont utilisées pour
        l'envoi de prospections destinées à promouvoir des produits ou services
        analogues et fournis par REWORLD MEDIA, vos coordonnées pourront être
        utilisées sans votre consentement. De même, votre consentement ne sera
        pas requis en matière de prospection par téléphone ou voie postale. Dans
        ces deux hypothèses, REWORLD MEDIA traite vos données aux fins
        d'intérêts considérés comme légitime par la réglementation, sans que
        cela n'emporte de conséquences sur vos droits. Lors de la collecte des
        Données personnelles, les formulaires de création de compte, de commande
        en ligne ou de renseignement précisent les données qui doivent
        impérativement être communiquées pour bénéficier du Service concerné,
        les données facultatives et les conséquences de la non-communication
        d'une donnée.
      </p>
      <p>&nbsp;</p>
      <ol start="3">
        <li>
          <span style="color: #333333"
            ><strong
              >Quels sont vos droits et comment les exercer ?</strong
            ></span
          >
        </li>
      </ol>
      <p>
        Conformément à la réglementation applicable, vous disposez de différents
        droits informatiques et libertés, à savoir :
      </p>
      <p>
        - Droit d'accès : vous pouvez obtenir des informations concernant le
        traitement de vos données personnelles ainsi qu'une copie de ces données
        personnelles.
      </p>
      <p>
        - Droit de rectification : si vous estimez que vos données personnelles
        sont inexactes ou incomplètes, vous pouvez exiger que ces données
        personnelles soient modifiées en conséquence.
      </p>
      <p>
        - Droit à l'effacement : vous pouvez exiger l'effacement de vos données
        personnelles dans la - limite de ce qui est permis par la
        réglementation.
      </p>
      <p>
        - Droit à la limitation du traitement : vous pouvez demander la
        limitation de traitement de vos données personnelles.
      </p>
      <p>
        - Droit d'opposition : vous pouvez vous opposer au traitement de vos
        données personnelles, pour des motifs liés à votre situation
        particulière. Vous disposez du droit absolu de vous opposer au
        traitement de vos données personnelles à des fins de prospection
        commerciale, y compris le profilage lié à cette prospection.
      </p>
      <p>
        - Droit à la portabilité de vos données : quand ce droit est applicable,
        vous avez le droit que les données personnelles que vous nous avez
        fournies vous soient rendues ou, lorsque cela est possible,
        techniquement, de les transférer à un tiers.
      </p>
      <p>
        - Droit de définir des directives relatives à la conservation,
        l'effacement ou la communication de vos données personnelles,
        applicables après votre décès.
      </p>
      <p>
        - Droit de retirer votre consentement : si vous avez donné votre
        consentement au traitement de vos données personnelles, vous avez le
        droit de retirer votre consentement à tout moment.
      </p>
      <p>
        Vous pouvez exercer ces droits en écrivant par lettre simple à l'adresse
        suivante Reworld Media France SAS, 8 rue François Ory - 92543 Montrouge
        ou en vous adressant à l'adresse suivante dpd@reworldmedia.com.
      </p>
      <p>
        Pour exercer votre droit d'opposition et vous désabonner de nos
        communications, vous pouvez également utiliser les liens de
        désabonnement présents sur chacune de nos communications qui vous
        permettent de vous désabonner d'une communication en particulier ou de
        vous désabonner de l'ensemble de nos communications.
      </p>
      <p>
        REWORLD MEDIA disposera d'un délai maximum de 1 (un) mois pour répondre
        suivant réception de votre demande. Ce délai pourra être prorogé de deux
        mois, compte tenu de la complexité et du nombre de demandes qui
        pourraient nous être adressées. Dans ce cas, nous vous informerons de
        cette prorogation et des motifs la justifiant.
      </p>
      <p>&nbsp;</p>
      <p>
        Vous disposez également d'un droit de réclamation auprès d'une autorité
        de contrôle (en France, l'autorité de contrôle est la CNIL - 3 place de
        Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07 -
        <a href="http://www.cnil.fr">www.cnil.fr</a>).
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "PrivacyPolicy",
  created() {},
  data() {
    return {};
  },
  methods: {},
  computed: {},
  mounted() {},
};
</script>

<style scoped></style>
